import { useState } from 'react';

import { getComplementaryWithdrawalBenefitApi } from '../../../services/banks/bmg/bmgAPI';

export const useComplementaryWithdrawalBenefit = () => {
  const [loading, setLoading] = useState(false);

  const getComplementaryWithdrawalBenefit = async (cpf) => {
    setLoading(true);
    const result = await getComplementaryWithdrawalBenefitApi(cpf);
    setLoading(false);
    return result;
  };

  return {
    loading,
    getComplementaryWithdrawalBenefit
  };
};
